<template>
  <v-sheet color="primary" class="home" style="height: 100vh">
    <div class="login d-flex align-center justify-center" style="height: 100%">
      <v-card max-width="600" class="pa-5 mx-3">
        <!-- Card Header -->
        <div class="d-flex align-center flex-column">
          <router-link to="/">
            <v-img
              src="~@/assets/logo.svg"
              min-height="75"
              max-width="200"
              contain
            ></v-img>
          </router-link>
          <v-card-title
            style="word-break: normal; text-align: center"
            class="headline"
            >Login</v-card-title
          >
        </div>
        <v-subheader class="error--text caption">{{ errorMsg }}</v-subheader>
        <!-- End Card Header -->
        <!-- Login Fields -->
        <div class="mt-2">
          <v-row class="mb-5">
            <v-col cols="12">
              <v-text-field
                label="Email"
                v-model="credentials.email"
                type="text"
                class="px-4"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Password"
                v-model="credentials.password"
                type="password"
                class="px-4"
                outlined
                @keydown.enter="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="px-7">
              <v-btn
                color="primary"
                large
                elevation="0"
                block
                :loading="loader"
                @click.stop="login"
                >Login</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>

// import * as fb from '../firebase'

export default {
  name: "Login",

  data: () => ({
    credentials: {
      email: "",
      password: "",
    },
    errorMsg: '',
    loader: false,
  }),


  methods: {
    async login() {

      this.loader = true

      const loginResult = await this.$store.dispatch('auth/loginWithFirebase', this.credentials)
      
      if(loginResult.code == 'auth/wrong-password' || loginResult.code == 'auth/user-not-found' || loginResult.code == 'auth/invalid-email') {
        this.errorMsg = 'Incorrect email or password'
        this.loader = false
      } else {
        this.loader = false
        if(loginResult.code === 1) {
          this.$router.push({ name: 'Manager' })
        }
      }
    }
  }
}
</script>
<style></style>
